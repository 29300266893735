/* General Styles */
body {
margin: 0;
padding: 0;
width: 100%;
background-color: #fffcf7;
color: #383b3d;
}

.text-center {
text-align: center;
}

/* Header Styles */
.logo {
width: 200px;
margin-left: 50px;
margin-top: 3px;
}
.product-detail-container .logo{
width: 200px;
margin-left: 50px;
margin-top: 3px;
}

.header {
background-color: #45625d;
padding: 10px;
}

.menu {
display: flex;
align-items: center;
}

.menu-left,
.menu-right {
display: flex;
align-items: center;
}
.menu-right {
display: flex;
margin-left: auto;
margin-top: 8px;
margin-right: 50px;
justify-content: flex-end;
}

.menu-link {
margin-left: 30px;
}

.car-info-buttons {
display: flex;
justify-content: center; 
gap: 30px; 
margin-bottom: 20px;
}
.menu-link a,
.listing-detail-button a,
.listing-add-to-cart-button a,
.menu-link1,
.menu-link2,
.menu-link3,
.add-car-button,.checkout-button
{
padding: 8px 15px;
background-color: #6aa5a9;
color: whitesmoke;
border: none;
border-radius: 5px;
text-decoration: none;
cursor: pointer;
transition: background-color 0.3s ease;
}
.menu-link a:hover,
.menu-link1:hover,
.menu-link2:hover,
.menu-link3:hover,
.listing-add-to-cart-button a:hover, 
.listing-detail-button a:hover,
.add-car-button:hover,
.checkout-button:hover
{
background-color: #51916a;
}

/* Home Content Styles */
.home-content-title{
margin-top: 50px;
text-align: center;
}

.car-listings {
display: flex;
flex-wrap: wrap;
justify-content: center;
margin-top: 80px;
gap: 60px;
}

.car-info {
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
}

.car-listing {
border: 1px solid #6aa5a9;
border-radius: 10px;
padding: 10px;
margin: 10px;
width: 300px;
text-align: left;
}

.car-image {
padding-top: 20px;
display: block;
margin: 0 auto;
width: 100%;
height: auto;
}

.car-info {
margin-top: 10px;
}

/* Sign In/up Page Styles */
.sign-in-page {
text-align: center;
}

.login-form,
.signup-form,
.profile-form {
max-width: 300px;
margin: 0 auto;
border: 1px solid #ddd;
padding: 20px;
border-radius: 8px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.signup-form button, 
.login-form button{
background-color: #a3d2d5;
}

.content{
margin-top: 40px;
margin-bottom: 5%;
text-align: center;
}
.signup-page h3{
text-align: center;
}
.signup-page button{
margin-left: 35%;
}
.form-group {
margin-bottom: 15px;
}

label {
display: block;
margin-bottom: 5px;
}

input {
width: 100%;
padding: 8px;
box-sizing: border-box;
margin-bottom: 10px;
}

button,
.delete-account-button,
.add-car-button {
padding: 10px 15px;
border: none;
border-radius: 5px;
cursor: pointer;
transition: background-color 0.3s ease;
}

button:hover,
.add-car-button:hover {
background-color: #7ec384;
}
.account-signup-suggest{
  padding-bottom: 5%;
}
/* Home Button Styles */
.home-button {
display: flex;
align-items: center;
text-decoration: none;
}

.home-icon {
font-size: 20px;
margin-left: 300px;
color: whitesmoke;
}
/* My Profile */
.my-profile-greeting-msg{
padding-bottom: 20px;
}
.sign-out-button{
background-color: #6aa5a9;
color: whitesmoke;
text-align: center;
margin-left: 20px;
}
.sign-out-button:hover{
background-color: #51916a;
}
.my-listings-header{
padding-bottom: 40px;
}
.my-listings-box{
display: flex;
margin:40px;
width: 80%;
}
.my-listings-box {
display: flex;
justify-content: center;
align-items: center;
margin: auto;
width: 90%;
}
.car-listing2{
padding: 20px;
}
.delete-account-button:hover{
color: #fff;
background-color: rgb(223, 103, 103);
}
.modify-listing-button-delete:hover{
  background-color: rgb(229, 165, 165);
}
/* Product Detail */
.product-detail-container img{
width: 30%;
padding-bottom: 20px;
}
.product-detail-container {
margin: auto;
text-align: center;
}
.product-detail-description {
font-style: italic;
}
.product-specs{
padding-bottom: 20px;
}
.delete-account-button{
margin-top: 3%;
}
.add-car-icon{
margin-top: 30px;
}
.modify-listing-button{
margin: 20px;
}
/* Edit Car Form */
.edit-car-modal {
  width: 70%;
  margin: auto;
  background-color: #a5c9cb;
  padding-bottom: 50px;
  border-radius: 20px;
  padding-top: 30px;
}
.edit-car-modal h2{
  text-align: center;
}
.edit-car-form{
  width: 60%;
  margin: auto;
}
.edit-car-form button{
margin-top: 20px;
margin-left: 20%;
} 
/* Add Car Page */
.add-car-form{
  width: 50%;
  margin: auto;
}
.add-car-content h2, h3{
  padding-top: 20px;
  text-align: center;
}
.add-car-content label{
font-weight: 600;
}
.add-car-form button{
margin-left: 25%;
}
/* Footer */
.footer {
  background-color: #6aa5a9;
  color: #fff;
  padding: 10px;
  text-align: center;
  position: relative;
  margin-top: 15%;
}

/*Shopping Cart Page*/
.shopping-cart{
  text-align: center;
}
.shopping-cart p{
  padding-bottom: 2%;
}
.shopping-cart h2{
padding-top: 3%;
}
.car-image2 {
width: 50%; 
margin: auto;
margin-top: 5%;
border-radius: 10px;
}
.all-car-item {
  border-radius: 10px;
  margin: auto;
  text-align: center;
}
.all-car-item button{
  margin-bottom: 3%;
}
.car-item {
  width: 40%;
  margin: auto;
  background-color: #a5c9cb;
}
.checkout-button{
  margin-right: 11%;
}
.shopping-cart-button{
  padding-top: 3%;
  margin-bottom: 20%;
  display: flex;
  margin-left: 35%;
}

/*checkout Page*/
.checkout-page{
  padding-top: 3%;
  width: 40%;
  margin: auto;
}
.checkout-buttons{
  display: flex;
  justify-content: space-between;
}